import React from "react";
import HomeTitleBack from "../Utils/HomeTitleBack";
import Layout from "../components/layout-reseller";
import imgMobile from "../Assets/portada-entrenamiento-mobile.webp";
import imgDesktop from "../Assets/portada-entrenamiento.webp";
import BannerStart from "../Utils/BannerStart";
import bannerMobile from "../Assets/banner_contact_mobile.webp";
import bannerDesktop from "../Assets/banner_contact_desktop.webp";
import CalendarEvent from "../components/calendar/CalendarEvent";
export default function entrenamientos() {
  return (
    <div>
      <Layout>
        <HomeTitleBack
          title={
            "Prepárate u organiza un entrenamiento con las mejores marcas de seguridad"
          }
          display={true}
          imgMobile={imgMobile}
          imgDesktop={imgDesktop}
        />
        <CalendarEvent />
        <BannerStart
          bannerMobile={bannerMobile}
          bannerDesktop={bannerDesktop}
          buttonText={"Contáctanos"}
          title={"Contáctanos para organizarlo"}
        />
      </Layout>
    </div>
  );
}
