import React from 'react';
import styled from 'styled-components';
import Clock from "../Assets/clock.svg";
import Modalidad from "../Assets/modalidad.svg";
import CalendarImg from "../Assets/calendar.svg";
import Close from "../Assets/icon_close.svg";
import Button from "./Button";
const StyledModals = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 85%;
  max-height: 700px;
  padding: 40px;
  margin: 32px;
  margin-top: -100px;
  font-family: "Noto_sans_regular";
  color: white;
  background-color: #303030;
  border-radius: 8px;

  .button_blue {
    margin: 44px auto 0;
    width: 90%;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin: 36px 0;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 24px;
      cursor: pointer;
    }
    button {
      border: none;
      background-color: transparent;
    }
  }
  .event_info {
    .order_info {
      display: flex;
      margin-bottom: 24px;
      img {
        width: 32px;
        height: 32px;
      }
      .order_top {
        display: flex;
        flex-direction: column;
        p {
          margin: 5px 10px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    margin-top: 50px;
  }
`;
export default function StyledModal(props) {
    const {title, description, imageAutor, instructor, time, modality, date, linkUrl}=props;
  return (
    <StyledModals>
    <div className="title">
      <h1>{title}</h1>
      <img src={Close} alt="close_icon" />
    </div>

    <p>
      {description}
    </p>
    <div className="event_info">
      <div className="order_info">
        {imageAutor === undefined ? null : <img src={imageAutor} alt="instructor_img" />}
        

        <div className="order_top">
          <p>{instructor}</p>
          <p>Instructor</p>
        </div>
      </div>
      <div className="order_info">
        <img src={CalendarImg} alt="instructor_img" />

        <div className="order_top">
          <p>{date}</p>
          <p>Fecha</p>
        </div>
      </div>
      <div className="order_info">
        <img src={Clock} alt="instructor_img" />

        <div className="order_top">
          <p>{time}</p>
          <p>Horario</p>
        </div>
      </div>
      <div className="order_info">
        <img src={Modalidad} alt="instructor_img" />

        <div className="order_top">
          <p>{modality}</p>
          <p>Modalidad</p>
        </div>
      </div>
      <Button textButton={"Inscríbete"} link={linkUrl} />
    </div>
  </StyledModals>
  )
}
