import React, { useEffect, useState, createContext } from "react";
import ReactCalendar from "react-calendar";
import styled from "styled-components";
import moment from "moment";
import "../../css/typographyc.css";
// import Clock from "../../Assets/clock.svg";
// import Modalidad from "../../Assets/modalidad.svg";
// import CalendarImg from "../../Assets/calendar.svg";
// import Close from "../../Assets/icon_close.svg";
// import Button from "../../Utils/Button";
import StyledModal from "../../Utils/StyledModal";
const StyledCalendar = styled.div`
  background-color: #1e1e1e;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  .react-calendar__navigation {
    display: flex;
    background-color: #1e1e1e;
    button {
      background-color: #1e1e1e !important;
      color: white !important;
      border: none;
      cursor: pointer;
    }
  }
  .react-calendar {
    width: 100%;
    padding: 20px;
  }
  .react-calendar__navigation__prev2-button{
    display: none;
  }
  .react-calendar__navigation__next2-button{
    display: none;
  }
  .react-calendar__navigation__label__labelText {
    font-family: "Noto_sans_regular" !important;
  }
  .react-calendar__month-view__weekdays {
    background-color: #1e1e1e;
    color: white;
    padding-top: 24px;
    padding-bottom: 12px;
    width: 90%;
    padding-left: 20px;
    abbr {
      font-family: "Noto_sans_regular" !important;
    }
  }
  .react-calendar__tile {
    background-color: #303030;
    border: none;
    width: 90px;
    cursor: pointer;
    abbr {
      color: white;
      font-family: "Noto_sans_regular" !important;
    }
  }
  .react-calendar__month-view__days {
    background-color: #1e1e1e;
  }
  .react-calendar__month-view__days__day {
    margin: 1px;
    height: 90px;
    border-radius: 4px;
  }
  @media (min-width: 1200px) {

    display: flex;
    flex-direction: row;
    padding-bottom: 100px;
    .react-calendar__month-view__weekdays {
      padding-left: 30px;
    }
  }
`;
const StyledInfoCalendar = styled.div`
  background-color: #303030;
  font-family: "Noto_sans_regular";
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  width: 85%;
  height: 500px;
  padding: 32px;
  margin: 32px;
  border-radius: 8px;
  @media (min-width: 800px) {
    margin-top: 50px;
  }
  @media (min-width: 1200px) {
    margin-top: 50px;
  }
`;

// const StyledModal = styled.div`
//   margin: 0 auto;
//   height: 100%;
//   width: 85%;
//   max-height: 700px;
//   padding: 40px;
//   margin: 32px;
//   margin-top: -100px;
//   font-family: "Noto_sans_regular";
//   color: white;
//   background-color: #303030;
//   border-radius: 8px;

//   .button_blue {
//     margin: 44px auto 0;
//     width: 90%;
//   }
//   h1 {
//     font-style: normal;
//     font-weight: 600;
//     font-size: 20px;
//     line-height: 20px;
//   }
//   p {
//     font-style: normal;
//     font-weight: 300;
//     font-size: 14px;
//     line-height: 20px;
//     margin: 36px 0;
//   }
//   .title {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     img {
//       width: 24px;
//       cursor: pointer;
//     }
//     button {
//       border: none;
//       background-color: transparent;
//     }
//   }
//   .event_info {
//     .order_info {
//       display: flex;
//       margin-bottom: 24px;
//       img {
//         width: 32px;
//         height: 32px;
//       }
//       .order_top {
//         display: flex;
//         flex-direction: column;
//         p {
//           margin: 5px 10px;
//         }
//       }
//     }
//   }
//   @media (min-width: 1200px) {
//     margin-top: 50px;
//   }
// `;
Array.prototype.getItemCount = function (item) {
  let counts = {};
  for (let i = 0; i < this.length; i++) {
    let num = this[i];
    counts[num] = counts[num] ? counts[num] + 1 : 1;
  }
  return counts[item] || 0;
};

export const indexState = createContext();

const Index = () => {
  const [day, setDay] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [classes, setClasses] = useState([]);

  const getClasses = async () => {
    const allDates = [{ date: "10-11-2022", full: true }];

    setClasses(allDates);
  };

  const Calendar = ({ setContent }) => {
    return (
      <>
        <ReactCalendar
          selectRange={false}
          tileContent={setContent}
          onClickDay={(value, event) => {
            console.log("day", moment(value).format("YYYY-MM-DD"));
            setSelectedDay(moment(value).format("YYYY-MM-DD"));
          }}
        />
      </>
    );
  };

  useEffect(() => {
    getClasses();
  }, [day]);
  useEffect(() => {
    getClasses();
  }, []);

  const setContent = ({ date }) => {
    const d8 = classes.find(
      (d) => d.date === moment(date).format("DD-MM-YYYY")
    );

    if (d8) {
      return (
        <div onClick={() => d8.full || setDay(date)}>
          <div
            style={{
              backgroundColor: d8.full ? "#7C7C7C" : "#a7ef94",
              borderRadius: "4px",
              marginTop: "-60px",
              color: "white",
            }}
          >
            <p>Perception <br /> Point</p>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setCurrentTime(null);
  }, [day]);

  return (
    <indexState.Provider
      value={{
        day,
        currentTime,
        setDay,
        setCurrentTime,
      }}
    >
      <StyledCalendar>
        <Calendar setContent={setContent} />
        {selectedDay === "2022-11-10" ? (
          <StyledModal
            instructor="Isaías Vara Flores"
            title="PERCEPTION POINT"
            description="Perspectiva de España, Israel, México... No te pierdas este evento único donde podrás conocer nuevas tecnologías, conocer a profundidad las tendencias en IT."
            date={selectedDay}
            time="11:00 am"
            modality="Online"
            linkUrl="https://perception-point.io/landing/webinar-securitry-blind-spots-era-cloud-communication-collaboration/"
          />
        ) : (
          //   <StyledModal>
          //     <div className="title">
          //       <h1>Evento Prueba</h1>
          //       <img src={Close} alt="close_icon"  onClick={() => setSelectedDay("00-00-00")}/>
          //     </div>

          //     <p>
          //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et
          //       tempor, diam, amet arcu nullam. Feugiat volutpat neque molestie
          //       vitae, amet. Odio tempor, et ornare faucibus facilisis sed purus.
          //       Lacinia feugiat hendrerit massa fermentum nunc.
          //     </p>
          //     <div className="event_info">
          //       <div className="order_info">
          //         <img src={""} alt="instructor_img" />

          //         <div className="order_top">
          //           <p>Andrés Johansen</p>
          //           <p>Instructor</p>
          //         </div>
          //       </div>
          //       <div className="order_info">
          //         <img src={CalendarImg} alt="instructor_img" />

          //         <div className="order_top">
          //           <p>21 de Julio, 2022</p>
          //           <p>Fecha</p>
          //         </div>
          //       </div>
          //       <div className="order_info">
          //         <img src={Clock} alt="instructor_img" />

          //         <div className="order_top">
          //           <p>08:00 am a 11:00am</p>
          //           <p>Horario</p>
          //         </div>
          //       </div>
          //       <div className="order_info">
          //         <img src={Modalidad} alt="instructor_img" />

          //         <div className="order_top">
          //           <p>Online</p>
          //           <p>Modalidad</p>
          //         </div>
          //       </div>
          //       <Button textButton={"Inscríbete"} link={"/contact"} />
          //     </div>
          //   </StyledModal>
          <StyledInfoCalendar>
            <p>No hay eventos para esta fecha</p>
          </StyledInfoCalendar>
        )}
      </StyledCalendar>
    </indexState.Provider>
  );
};

export default Index;
